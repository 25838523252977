import OnDemandThankYouPage from "components/thank-you/on-demand-video/OnDemandThankYouPage";
import React from "react";

export default function ThankYouPageRoot() {
  return (
    <OnDemandThankYouPage
      videoId="hjbri2xoqf"
      title="How the Safer Georgia Schools Grant and Rhombus Can Help Foster a Safe and Healthy Campus"
      description="Discover how Rhombus and the Safer Georgia Schools Grant work hand-in-hand to create a safer and healthier campus by through cloud-based security cameras, smart sensors, and advanced analytics."
    />
  );
}
